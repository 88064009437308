import React from "react"
import Header from "../../components/header"
import HeaderLeistung from "../../components/headerLeistung"
import LeistungBox from "../../components/boxLeistung"
import Footer from "../../components/footer"
import ContactBox from "../../components/boxContact"

import imgHolz01 from "../../assets/leistungen/holz01.jpg"
import imgHolz02 from "../../assets/leistungen/holz02.jpg"
import imgHolz03 from "../../assets/leistungen/holz03.jpg"

export default () => (
  <div>
    <Header />
    <HeaderLeistung
      Title="Holzarbeiten &amp; Zimmerarbeiten"
      Content="Wir bearbeiten und verarbeiten den Naturbaustoff Holz nach Ihren&nbsp; 
        Wünschen. Dazu zählen etwa Sparrenwechsel für den&nbsp; 
        Dachfenstereinbau, die Verbreiterung von Dachüberständen, die&nbsp; 
        Stellung von Dachgauben oder der Aufbau kompletter&nbsp; 
        Dachkonstruktionen. Auch kleinere Reparaturen am Dachgebälk können&nbsp; 
        Sie uns anvertrauen."
    />
    <LeistungBox
      Color="gray"
      Image={imgHolz01}
      IsImageLeft={true}
      Title="Sparrenwechsel &amp; Verbreiterung der Dachüberstände"
      Content="Beim Einbau von Dachfenstern sind zum Teil Änderungen am&nbsp; 
        vorhandenen Dachstuhl notwendig. Je nach Dachausbau können wir&nbsp; 
        dies meist ohne größeren Aufwand durch einen Sparrenwechsel&nbsp; 
        erledigen. Häufig übernehmen wir auch die Verbreiterung von&nbsp; 
        Dachüberständen. Nötig wird dies heute häufig durch&nbsp; 
        Fassadendämmungen und der damit verbundenen Verkleinerung des&nbsp; 
        vorhandenen Dachüberstandes."
    />
    <LeistungBox
      Color="white"
      Image={imgHolz02}
      IsImageLeft={false}
      Title="Dachgauben"
      Content="Typisch für eine Dachgaube ist die senkrechte Fensterfläche, die&nbsp; 
        schräg im Dach verbaut wird. Ziel einer Gaube ist es, einen&nbsp; 
        optimalen Lichteinfall und einen möglichst großen Raumgewinn zu&nbsp; 
        ermöglichen. Eine Dachgaube lässt sich in jede Art von&nbsp; 
        Dachstuhlkonstruktion einfügen und zwar unabhängig davon, ob das&nbsp; 
        Dachgeschoss ausgebaut ist oder nicht. Eine Dachgaube ist nach&nbsp; 
        außen hin deutlich sichtbar und eignet sich damit auch als&nbsp; 
        gestalterisches Element zur Verschönerung Ihres Hauses. Die&nbsp; 
        Varianten in Form und Ausführung sind dabei sehr vielfältig,&nbsp; 
        sodass individuelle Wünsche berücksichtigt werden können."
    />
    <LeistungBox
      Color="gray"
      Image={imgHolz03}
      IsImageLeft={true}
      Title="Ausbesserungsarbeiten"
      Content="Auch die Balken des Dachbodens kommen in die Jahre. Wenn die&nbsp; 
        Oberfläche weich wird und anfängt zu krümeln, ist es Zeit für eine&nbsp; 
        Überprüfung. Im Zweifel müssen Balken bzw. Teile davon&nbsp; 
        ausgetauscht werden."
    />
    <ContactBox isFoot="true" />
    <Footer />
  </div>
)
